/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

/* NYC User Experience Design*/
body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333333;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
    color: #000000;
    letter-spacing: -1px;
}

h1 {
    font-size: 39px !important;
}

h2 {
    font-size: 36px !important;
}

h3 {
    font-size: 24px !important;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 12px;
}

/* Provide sufficient contrast against white background */
a, a:link, a:visited {
    color: #0066cc;
    text-decoration: none;
}

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    a.btn-primary, .btn-primary {
        color: #fff;
        background-color: #0066cc;
        border-color: #0066cc;
    }

    a.btn-outline-primary, .btn-outline-primary {
        border-color: #0066cc;
    }

        a.btn-outline-primary:hover {
            color: #fff;
            background-color: #0066cc;
            border-color: #0066cc;
        }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #0066cc;
    border-color: #0066cc;
}

@media (max-width: 767px) {
    .sc-vert-menu .nav-link {
        min-width: 175px;
        max-width: 175px;
    }
}

/* Sticky footer styles
-------------------------------------------------- */
@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    line-height: 60px; /*Vertically center the text there */
    background-color: black;
}

.checkbox-fix {
    margin-left: 8px;
}

.sc-link {
    font-size: 1.2rem;
    font-weight: bold;
}

.sc-lead {
    font-size: 1.5rem;
    font-weight: 300;
}

    .sc-lead a {
        font-weight: 400;
    }

.sc-active-step {
    margin-top: 16px;
    margin-bottom: 16px;
    /*    font-size: 1.75rem;*/
    /*    font-weight: 700;*/
}

.sc-inactive-step {
    opacity: 0.5;
    margin-top: 16px;
}

/* Logos are used for course providers and host company or other images */
.sc-table-logo {
    object-fit: contain;
    width: 2.2rem;
    height: 2.2rem;
    padding: 0px;
}

.sc-title-logo {
    object-fit: contain;
    width: auto;
    height: 3.5rem;
}

.sc-header-logo {
    object-fit: contain;
    width: 150px;
    height: auto;
}

.sc-list-logo {
    object-fit: contain;
    width: 150px;
    height: 100%;
}

.sc-navbar-logo {
    object-fit: contain;
    width: auto;
    height: 1.8rem;
}

@media(min-width: 576px) {
    .sc-navbar-logo {
        height: 2.0rem;
    }
}

.sc-quicklink-logo {
    object-fit: contain;
    width: auto;
    height: 1.0rem;
    padding: 0px;
    margin-right: 6px;
    margin-bottom: 5px;
}

.sc-section-logo {
    object-fit: contain;
    width: auto;
    height: 1.5rem;
    padding: 0px;
    margin-right: 6px;
    margin-bottom: 5px;
}

/* Photos are used for profile pictures and are rounded to be a circle*/
.sc-list-photo {
    object-fit: cover;
    padding: 0px;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
}

.sc-title-photo {
    object-fit: cover;
    padding: 0px;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
}

.sc-header-photo {
    object-fit: cover;
    padding: 0px;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
}

.sc-field-name {
    font-size: 1.0rem;
    font-weight: 400;
}

.sc-field-value {
    font-weight: 700;
    font-size: 1.0rem;
}

.sc-nowrap {
    white-space: nowrap;
}

@media (min-width: 768px) {
    .sc-md-nowrap {
        white-space: nowrap;
    }
}

.sc-thin {
    font-weight: 400;
}

.sc-card-id {
    font-family: Consolas, monospace;
}

.sc-card-preview {
    border-radius: 15px;
    box-shadow: 4px 4px 15px #888888;
}

a.sc-dashboard-link:hover {
    color: white;
}

a.sc-dashboard-link {
    color: rgba(255,255,255,.8);
}

a.sc-dashboard-brand:hover {
    color: white;
}

a.sc-dashboard-brand {
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0px;
    white-space: nowrap;
}

@media (min-width:576px) {
    .sc-dashboard-brand {
        font-size: 1.5rem;
        font-weight: 500;
    }
}

/* NYC.ID Header*/

.container-nycidm {
    *zoom: 1;
    max-width: 1140px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

    .container-nycidm:after {
        content: "";
        display: table;
        clear: both
    }

    .container-nycidm:before {
        display: table;
        line-height: 0;
        content: ""
    }

.nycidm-header .upper-header-black {
    background: #000;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    height: 25px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
}

    .nycidm-header .upper-header-black a {
        color: #fff;
        text-decoration: none;
    }

.nycidm-header .small-nyc-logo {
    height: 15px;
    width: 40px;
    position: relative;
}

.nycidm-header .upper-header-black-title {
    position: relative;
    top: 2px;
}

.nycidm-header .upper-header-b, .nycidm-header .upper-header-a {
    float: right;
    padding-top: 5px;
}

.nycidm-header .vert-divide {
    margin: 0 10px;
}

.vert-divide-right {
    float: right;
    margin: 0 10px;
}

@media only screen and (max-width:576px) {
    .nycidm-header .upper-header-black-title {
        display: none;
    }
}

/* Google Translate overrides*/

div .goog-te-gadget {
    font-family: arial;
    font-size: 11px;
    color: lightgray;
    white-space: nowrap;
}

div .goog-logo-link, div .goog-logo-link:link, div .goog-logo-link:visited, div .goog-logo-link:hover, div .goog-logo-link:active {
    font-size: 12px;
    font-weight: bold;
    color: lightgray;
    text-decoration: none;
}

/* NYC Footer*/

footer {
    background-color: #000;
    padding: 35px 0 60px 0;
    clear: both;
    /*    bottom: 0px;*/
    /*    position: absolute;*/
    width: 100%;
}

    footer .footerwrap {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-line-pack: start;
        align-content: flex-start;
    }

        footer .footerwrap div.footerLegal {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            color: #fff;
            font-size: .75rem;
            line-height: 1.25;
            margin-top: .75rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
        }

@media (min-width: 600px) {
    footer .footerwrap div.footerLegal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 55%;
        flex-basis: 55%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-line-pack: justify;
        align-content: space-between;
    }
}

@media (min-width: 775px) {
    footer .footerwrap div.footerLegal {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        max-width: 50%;
        position: relative;
        margin-left: auto;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: right;
        position: relative;
    }
}

footer .footerwrap div.footerSearch {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: .75rem;
}

@media (min-width: 600px) {
    footer .footerwrap div.footerSearch {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
}

@media (min-width: 775px) {
    footer .footerwrap div.footerSearch {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-preferred-size: 50%;
        flex-basis: auto;
        padding-right: 1.5rem;
    }
}

footer li {
    line-height: 20px;
}

footer a {
    color: #017dbb;
    text-decoration: none;
}

footer .footerwrap *, .footerwrap ::after, .footerwrap ::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

footer a:link {
    color: #1771b7;
    outline: 0;
}

footer .container {
    max-width: 940px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

footer .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    left: -10000px;
    top: auto;
}

footer .footerwrap ul {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    margin: 0 0 1.5rem;
    padding: 0 0 1.5rem;
    background: linear-gradient(to top,rgba(255,255,255,.5) 1px,transparent 1px);
}


footer .footerwrap a {
    color: #fff;
    font-weight: 700;
}

footer .footerwrap ul li {
    margin: 0;
    padding: 0;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}

    footer .footerwrap ul li:nth-of-type(n+3) {
        margin-top: .75rem;
    }

@media (min-width: 600px) {
    footer .footerwrap ul.footerLang li {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    footer .footerwrap ul.footerLang li:nth-of-type(n+6) {
        margin-top: .75rem;
    }
}

@media (min-width: 600px) {
    footer .footerwrap ul.footerLinks li {
        -ms-flex-preferred-size: 33.333%;
        flex-basis: 33.333%;
        margin-top: 0;
    }
}

@media (min-width: 1000px) {
    footer .footerwrap ul.footerLinks li {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    footer .footerwrap ul.footerLinks li:nth-of-type(n+4) {
        margin-top: .75rem;
    }
}

@media (min-width: 1000px) {
    footer .footerwrap ul.footerLinks li:nth-of-type(n+5) {
        margin-top: .75rem;
    }
}

footer img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

footer .footerwrap div.footerSearch a img {
    max-height: 2rem;
}
